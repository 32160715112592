














































































































import Vue from "vue";

export default Vue.extend({
  name: "wellcome",
  data() {
    return {
      showSection: false,
    };
  },
});
