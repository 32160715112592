var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('main-section',{attrs:{"text":_vm.$t('text1')}}),_c('products'),_c('v-lazy',{attrs:{"options":{
      threshold: 0.5,
    },"min-height":"200"},model:{value:(_vm.showSection1),callback:function ($$v) {_vm.showSection1=$$v},expression:"showSection1"}},[_c('wellcome',{directives:[{name:"anime",rawName:"v-anime",value:(
        _vm.showSection1 && {
          scale: [2, 1],
          translateX: ['40%', 0],
          opacity: [0, 1],
          easing: 'easeInOutExpo',
          duration: 1500,
        }
      ),expression:"\n        showSection1 && {\n          scale: [2, 1],\n          translateX: ['40%', 0],\n          opacity: [0, 1],\n          easing: 'easeInOutExpo',\n          duration: 1500,\n        }\n      "}]})],1),_c('v-lazy',{attrs:{"options":{
      threshold: 0.5,
    },"min-height":"200"},model:{value:(_vm.showSection2),callback:function ($$v) {_vm.showSection2=$$v},expression:"showSection2"}},[_c('looking-for',{directives:[{name:"anime",rawName:"v-anime",value:(
        _vm.showSection2 && {
          scale: [1.5, 1],
          translateY: ['40%', 0],
          opacity: [0, 1],
          easing: 'easeInOutExpo',
          duration: 1500,
        }
      ),expression:"\n        showSection2 && {\n          scale: [1.5, 1],\n          translateY: ['40%', 0],\n          opacity: [0, 1],\n          easing: 'easeInOutExpo',\n          duration: 1500,\n        }\n      "}],staticClass:"mt-5"})],1),_vm._v(" "+_vm._s(/* Logos */)+" "),_c('v-lazy',{staticClass:"d-flex align-center justify-center",attrs:{"options":{
      threshold: 0.5,
    },"min-height":"200"},model:{value:(_vm.showSection3),callback:function ($$v) {_vm.showSection3=$$v},expression:"showSection3"}},[_c('logo-companies',{directives:[{name:"anime",rawName:"v-anime",value:(
        _vm.showSection3 && {
          scale: [0.4, 1],
          opacity: [0, 1],
          easing: 'easeInOutExpo',
          duration: 1500,
        }
      ),expression:"\n        showSection3 && {\n          scale: [0.4, 1],\n          opacity: [0, 1],\n          easing: 'easeInOutExpo',\n          duration: 1500,\n        }\n      "}],staticClass:"fill-width"})],1),_c('contact',{staticClass:"mb-5"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }