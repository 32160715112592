



































































import Vue from "vue";

export default Vue.extend({
  name: "contact",
  data() {
    return {
      triggerAnimation: false,
    };
  },
});
