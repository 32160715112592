var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-lazy',{model:{value:(_vm.showSection),callback:function ($$v) {_vm.showSection=$$v},expression:"showSection"}},[_c('v-row',{staticClass:"wellcome grey darken-3 overflow-visible position-relative",style:({
      zIndex: 2,
    }),attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-5 pl-5 pr-5 pr-sm-5 pr-md-0",attrs:{"cols":"12","sm":"12","md":"6","lg":"5","xl":"6"}},[_c('v-layout',{staticClass:"fill-height",attrs:{"column":"","align-center":"","justify-center":""}},[_c('div',{staticClass:"d-flex flex-column fill-width mb-4"},[_c('div',{directives:[{name:"anime",rawName:"v-anime",value:(
              _vm.showSection && {
                scale: [1.5, 1],
                translateX: ['40%', 0],
                opacity: [0, 1],
                easing: 'easeInOutExpo',
                duration: 1500,
                delay: 500,
              }
            ),expression:"\n              showSection && {\n                scale: [1.5, 1],\n                translateX: ['40%', 0],\n                opacity: [0, 1],\n                easing: 'easeInOutExpo',\n                duration: 1500,\n                delay: 500,\n              }\n            "}],staticClass:"\n              font-weight-bold\n              grey--text\n              text--lighten-5 text-h3 text-uppercase\n              font-weight-medium\n            "},[_vm._v(" "+_vm._s(_vm.$t("wellcomeTo"))+" ")]),_c('div',{directives:[{name:"anime",rawName:"v-anime",value:(
              _vm.showSection && {
                scale: [1.5, 1],
                translateX: ['40%', 0],
                opacity: [0, 1],
                easing: 'easeInOutExpo',
                duration: 1500,
              }
            ),expression:"\n              showSection && {\n                scale: [1.5, 1],\n                translateX: ['40%', 0],\n                opacity: [0, 1],\n                easing: 'easeInOutExpo',\n                duration: 1500,\n              }\n            "}],staticClass:"\n              text-h3\n              primary-text-color\n              font-weight-bold\n              text-uppercase\n              font-weight-medium\n              mr-0 mr-sm-3\n            "},[_vm._v(" "+_vm._s(_vm.$t("jatiTruckSale"))+" ")])]),_c('p',{directives:[{name:"anime",rawName:"v-anime",value:(
            _vm.showSection && {
              scale: [1.5, 1],
              translateX: ['40%', 0],
              opacity: [0, 1],
              easing: 'easeInOutExpo',
              duration: 1500,
              delay: 1000,
            }
          ),expression:"\n            showSection && {\n              scale: [1.5, 1],\n              translateX: ['40%', 0],\n              opacity: [0, 1],\n              easing: 'easeInOutExpo',\n              duration: 1500,\n              delay: 1000,\n            }\n          "}],staticClass:"text-h6 grey--text text--lighten-5 text-justify",domProps:{"innerHTML":_vm._s(_vm.$t('content'))}})])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"d-flex overflow-hidden",attrs:{"cols":"12","sm":"12","md":"6","lg":"7","xl":"6"}},[_c('img',{directives:[{name:"anime",rawName:"v-anime",value:(
          _vm.showSection && {
            translateX: ['200%', _vm.$vuetify.breakpoint.xsOnly ? '-17%' : 0],
            opacity: [0, 1],
            easing: 'easeInOutExpo',
            duration: 1500,
            delay: 1500,
          }
        ),expression:"\n          showSection && {\n            translateX: ['200%', $vuetify.breakpoint.xsOnly ? '-17%' : 0],\n            opacity: [0, 1],\n            easing: 'easeInOutExpo',\n            duration: 1500,\n            delay: 1500,\n          }\n        "}],staticClass:"mx-auto",class:{
          'wellcome-image': _vm.$vuetify.breakpoint.xsOnly,
        },attrs:{"src":require('@/assets/img/section.png'),"width":"auto","height":"100%"}})]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }