


















import Vue from "vue";
import Subscribe from "./Subscribe.vue";
export default Vue.extend({
  name: "products",
  components: {
    Subscribe,
  },
});
