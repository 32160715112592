var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-lazy',{ref:"section4",attrs:{"options":{
    threshold: 0.5,
  },"min-height":"200"},model:{value:(_vm.triggerAnimation),callback:function ($$v) {_vm.triggerAnimation=$$v},expression:"triggerAnimation"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"overflow-hidden",attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-img',{directives:[{name:"anime",rawName:"v-anime",value:(
          _vm.triggerAnimation && {
            translateX: ['-20%', '0'],
            opacity: [0, 1],
            duration: 1500,
          }
        ),expression:"\n          triggerAnimation && {\n            translateX: ['-20%', '0'],\n            opacity: [0, 1],\n            duration: 1500,\n          }\n        "}],attrs:{"src":require("./../../assets/img/truck2.jpg"),"height":"100%"}})],1),_c('v-col',{staticClass:"grey darken-4 pa-3 pa-sm-4 pa-md-16 pa-lg-16 pa-xl-16 d-flex flex-column justify-center align-start diagonal",attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('div',{directives:[{name:"anime",rawName:"v-anime",value:(
          _vm.triggerAnimation && {
            translateX: ['20%', '0'],
            opacity: [0, 1],
            duration: 1500,
            delay: 500,
          }
        ),expression:"\n          triggerAnimation && {\n            translateX: ['20%', '0'],\n            opacity: [0, 1],\n            duration: 1500,\n            delay: 500,\n          }\n        "}],staticClass:"text-h4 font-weight-bold text-uppercase grey--text text--lighten-5 text-center text-sm-center text-md-left text-lg-left text-xl-left"},[_vm._v(" "+_vm._s(_vm.$t("text1"))+" ")]),_c('v-btn',{directives:[{name:"anime",rawName:"v-anime",value:(
          _vm.triggerAnimation && {
            translateX: ['20%', '0'],
            opacity: [0, 1],
            duration: 1000,
            delay: 600,
          }
        ),expression:"\n          triggerAnimation && {\n            translateX: ['20%', '0'],\n            opacity: [0, 1],\n            duration: 1000,\n            delay: 600,\n          }\n        "}],staticClass:"px-10 mt-8 font-weight-bold text-capitalize text-h6 align-self-center align-self-sm-center align-self-md-start align-self-lg-start align-self-xl-start",attrs:{"color":"primary","to":"/contact","depressed":"","tile":""}},[_vm._v(" "+_vm._s(_vm.$t("labelContactUs"))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-email ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }