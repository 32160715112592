












import Vue from "vue";

export default Vue.extend({
  name: "logo-companies",
  data() {
    return {
      logos: [
        "/img/logo-1.jpeg",
        "/img/logo-2.jpeg",
        "/img/logo-3.png",
        "/img/logo-4.png",
        "/img/logo-5.gif",
      ],
    };
  },
});
