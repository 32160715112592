









































































import Vue from "vue";
import Contact from "./Contact.vue";
import Products from "./Products.vue";
import LogoCompanies from "./LogoCompanies.vue";
import LookingFor from "./LookingFor.vue";
import wellcome from "./wellcome.vue";
import MainSection from "@/components/MainSection.vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "home",
  components: {
    wellcome,
    LookingFor,
    LogoCompanies,
    Contact,
    Products,
    MainSection
  },
  data() {
    return {
      showSection1: false,
      showSection2: false,
      showSection3: false,
      showSection4: false,
      dialogSubscribe: false,
    };
  },
  computed: {
    ...mapState(["brands"]),
  },
});
