
























































































































































































































































import Vue from "vue";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import { httpClient } from "@/services";

const SUBSCRIBE_BY_EMAIL = 0;
const SUBSCRIBE_BY_PHONE = 1;

export default Vue.extend({
  name: "subscribe",
  components: {
    VueHcaptcha,
  },
  data() {
    return {
      triggerAnimation: false,
      mainFormValid: false,
      codeFormValid: false,
      emailOrPhone: "",
      subscibeBy: 0, // Register By email or phone
      verified: false,
      expired: false,
      setCodeDialog: false,
      verificationCode: "",
      captchaKey: "",
      debug: {},
      subscriptionOk: false,
      codeInvalid: false,
      errorCodeActive: false,
      loadingSubscribeRequest: false,
      loadingSubscribe: false,
      dialogSubscribe: false,
      ...{ SUBSCRIBE_BY_EMAIL, SUBSCRIBE_BY_PHONE },
      validatePhone: (v: string) =>
        /*Samples phones
          123 456 7890
          (123)456 7890
          (123) 456 7890
          (123)456-7890
          (123) 456-7890
          123.456.7890
          123-456-7890
          1234567890
        */
        /^((\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4})|([(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4})|[0-9]+$/.test(
          v
        ) || this.$t("phoneIncorrect") + "",
      validateEmail: (value: string): boolean | string => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return (
          pattern.test(value) ||
          (this.$i18n.locale == "en"
            ? "Invalid email"
            : "Dirección de correo inválida")
        );
      },
      required: (value: string): boolean | string =>
        !!value || (this.$i18n.locale == "en" ? "Required" : "Requerido"),
    };
  },
  methods: {
    onVerify(token: string, captchaKey: string) {
      this.verified = true;
      this.captchaKey = captchaKey;
    },
    onExpire() {
      this.verified = false;
      this.expired = true;
    },
    // onError(err: any) {
    //   console.log(err);
    // },
    onSubscribeRequest() {
      // Solo funciona si el formulario esta validado
      if (this.mainFormValid == false) return;

      this.loadingSubscribeRequest = true;
      this.errorCodeActive = false;
      const body =
        this.subscibeBy == SUBSCRIBE_BY_PHONE
          ? {
              hcaptcha: this.captchaKey,
              phone: "1" + this.emailOrPhone.trim(),
            }
          : {
              hcaptcha: this.captchaKey,
              email: this.emailOrPhone.trim(),
            };

      httpClient
        .post("/user/subscribe", body)
        .then((response) => {
          if (response.data == true) {
            this.setCodeDialog = true;
            this.loadingSubscribeRequest = false;
          }
        })
        .catch((error: any) => {
          this.errorCodeActive = true;
          this.loadingSubscribeRequest = false;
        });
    },
    onSubmitSubscription(): void {
      // Solo funciona si el formulario esta validado
      if (this.codeFormValid == false) return;

      this.loadingSubscribe = true;
      this.codeInvalid = false;
      const url =
        this.subscibeBy == SUBSCRIBE_BY_PHONE
          ? "/sms/check"
          : "/user/check-email";
      const body =
        this.subscibeBy == SUBSCRIBE_BY_PHONE
          ? {
              code: this.verificationCode,
              phone: "1" + this.emailOrPhone.trim(),
            }
          : {
              code: this.verificationCode,
              email: this.emailOrPhone.trim(),
            };

      httpClient
        .post(url, body)
        .then((response) => {
          if (response.data.status == "OK") {
            this.setCodeDialog = false;
            this.subscriptionOk = true;
            this.loadingSubscribe = false;
          }
        })
        .catch((error) => {
          this.codeInvalid = true;
          this.loadingSubscribe = false;
        });
    },
  },
  watch: {
    subscibeBy(newVal, oldVal) {
      if (newVal != oldVal) {
        (this.$refs.subscribeField as any).resetValidation();
        this.emailOrPhone = "";
        this.mainFormValid = false;
      }
    },
  },
});
